import Axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { MdMailOutline, MdPhone } from 'react-icons/md';

import Loader from '../components/Loader';

const Address = styled.address`
  font-style: normal;

  svg {
    font-size: 42px;
    /* color: #3e3e3e; */
  }

  span {
    color: var(--primary-color);
  }

  > a {
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;

    * + * {
      margin-top: 1em;
    }
  }
`;

function ContactForm() {
  const [httpState, setHttpState] = useState(null);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setHttpState('loading');

    try {
      await Axios.post(`${process.env.GATSBY_API_URL}/contact`, data, {
        timeout: 5000,
      });
      setHttpState('success');
    } catch (error) {
      setHttpState('error');
    }
  };

  return (
    <div>
      <div className="box" style={{ marginBottom: '4em' }}>
        <h2>Kontakt</h2>
        {httpState !== 'success' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {httpState === 'error' && (
              <div className="alert error">
                Bei der Anfrage ist ein Fehler aufgetreten.
              </div>
            )}
            <div className="input-section">
              <label>
                Firma
                <input type="text" {...register('firma', { required: true })} />
              </label>
            </div>
            <div className="grid two small-gap">
              <div className="input-section">
                <label>
                  Name
                  <input
                    type="text"
                    {...register('name', { required: true })}
                  />
                </label>
              </div>
              <div className="input-section">
                <label>
                  Email
                  <input
                    type="email"
                    {...register('email', { required: true })}
                  />
                </label>
              </div>
            </div>
            <div className="input-section">
              <label>
                Nachricht
                <textarea
                  {...register('message', { required: true })}
                  style={{ minHeight: '180px' }}
                ></textarea>
              </label>
            </div>
            <div className="right">
              {httpState !== 'loading' ? (
                <button type="submit" className="button primary stretch">
                  Senden
                </button>
              ) : (
                <Loader />
              )}
            </div>
          </form>
        )}

        {httpState === 'success' && (
          <div className="alert success">Vielen Dank für ihre Anfrage!</div>
        )}
      </div>

      <hr />
      <div>
        <p>
          Lippmann German Ropes GmbH & Co. KG <br />
          Dubbenwinkel 11 <br />
          21147 Hamburg <br />
          Deutschland
        </p>
      </div>
      <hr />
      <Address className="grid two wrapper">
        <a href="mailto:info@lippmann.de">
          <MdMailOutline />
          <span>info@lippmann.de</span>
        </a>

        <a href="tel:+0407970050">
          <MdPhone />
          <span>040 - 797 005 - 0</span>
        </a>
      </Address>
      <hr />
    </div>
  );
}

export default ContactForm;
