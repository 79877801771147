import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import ContentSection from './ContentSection';
import PageBanner from './PageBanner';

const Container = styled.div`
  grid-auto-flow: dense;

  @media only screen and (max-width: 1020px) {
    grid-template-columns: 1fr !important;
  }
`;

function Custom() {
  return (
    <>
      <ContentSection>
        <div className="wrapper">
          <Container className="grid two">
            <Fade duration={1000}>
              <StaticImage
                className="media"
                src="../../images/programm1.jpg"
                alt="Seile"
                style={{ width: 'auto', objectFit: 'contain' }}
              />
            </Fade>

            <div>
              <Fade bottom duration={1000}>
                <h3>Großes Lieferprogramm</h3>
              </Fade>
              <Fade bottom duration={1000} delay={500}>
                <div>
                  <h4 className="serif">
                    Dyneema | Polyester | Hanf | Polyamid | Baumwolle
                    Polypropylen | Sisal | Aramid | Manila | Vectran
                  </h4>
                  <p>
                    Neben allen handelsüblichen Seilen aus den meist verwendeten
                    Materialien, in geflochten oder gedreht, von 0,1 bis 200 mm
                    Durchmesser, stellen wir heute auch jede denkbare
                    Spezialanfertigung für unsere Kunden her. Die eigene
                    Entwicklungsabteilung, eigene Werkstätten und ein eigenes
                    Prüflabor machen dies möglich
                  </p>
                </div>
              </Fade>
            </div>
          </Container>
        </div>
      </ContentSection>
      <PageBanner image="/p3130772_01.jpg" />
    </>
  );
}

export default Custom;
