import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';

import ContentSection from './ContentSection';
import PageBanner from './PageBanner';

function Custom() {
  return (
    <>
      <ContentSection>
        <Fade bottom duration={1000}>
          <div className="wrapper">
            <div className="grid two">
              <StaticImage
                src="../../images/atoz1.jpg"
                className="media"
                alt="Bild aus der Produktion"
              />
              <div>
                <h3>Seile von A bis Z</h3>
                <p>
                  Alle verfügbaren Rohstoffe. Alle Herstellungsmethoden. Alle
                  Durchmesser. Alle Konfektionierungsarten. Das ist unser
                  Leistungsportfolio. Mehr geht nicht. Weniger wollen wir auch
                  nicht!
                </p>
                <div className="grid two">
                  <StaticImage
                    src="../../images/atoz2.jpg"
                    className="media"
                    alt="Bild 2 aus der Produktion"
                  />
                  <StaticImage
                    src="../../images/atoz3.jpg"
                    className="media"
                    alt="Bild 3 aus der Produktion"
                  />
                </div>
                <hr className="space" />
                <div className="right">
                  <AnchorLink
                    to="/#produkte"
                    title="Kontakt"
                    className="button primary"
                  >
                    Zu den Produktkatalogen
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </ContentSection>
      <PageBanner image="/atozbg.jpg" />
    </>
  );
}

export default Custom;
