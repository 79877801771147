import React from 'react';

function MicrositeLink({ siteId = '', children, ...rest }) {
  const href = `https://microsite.lippmann.de/${siteId}`;

  return (
    <a href={href} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </a>
  );
}

export default MicrositeLink;
