import React from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import styled from 'styled-components';

const BannerContainer = styled(ParallaxBannerLayer)`
  filter: brightness(80%) blur(1px);
`;

const PageBanner = ({ image }) => {
  console.log(image);
  return (
    <ParallaxBanner style={{ aspectRatio: '3/1' }}>
      <BannerContainer image={image} speed={-30} />
    </ParallaxBanner>
  );
};
export default PageBanner;
