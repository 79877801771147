import React from 'react';
import { Fade } from '../../lib/Reveal';

import MicrositeLink from '../../components/MicrositeLink';

const ProductCard = ({ title, siteId, children, revealDelay = 0 }) => {
  return (
    <Fade duration={650} delay={revealDelay}>
      <div className="card">
        <MicrositeLink siteId={siteId}>
          <img
            style={{ height: '250px', objectFit: 'cover' }}
            src={`https://contentcrawler.de/media/${siteId}.jpg`}
            alt={title}
          />
        </MicrositeLink>

        <div className="card__content">
          <h3>{title}</h3>
          <p>{children}</p>
        </div>
        <MicrositeLink siteId={siteId} className="button big ghost">
           
        </MicrositeLink>
      </div>
    </Fade>
  );
};

function Produkte() {
  return (
    <div id="produkte" style={{ padding: '6em 0' }}>
      <div className="wrapper m center">
        <h2>Produktkataloge</h2>
        <p style={{ textAlign: 'center', fontSize: '1.2em' }}>
          Detaillierte technische Daten und Informationen über die mehr als{' '}
          <b>3.000 verschiedenen Produkte</b> stellen wir ihnen auf unseren
          entsprechenden online Produktkatalogen zur Verfügung.
        </p>
        <p style={{ fontSize: '0.9em', fontWeight: '300' }}>
          Bitte haben Sie Verständnis, dass wir nur gewerbliche Anfragen
          berücksichtigen können.
        </p>
      </div>
      <div className="space grid grid auto__300px wrapper xl">
        <ProductCard
          siteId="lgr"
          title="Seile für den Handel, Handwerk und Industrie"
        >
          Für Handel, Handwerk und Industrie
        </ProductCard>

        <ProductCard
          siteId="dynatec"
          title="Dynatec-Programm (HMPE-Seile)"
          revealDelay={150}
        >
          Hochfeste Dynatec Seile ersetzen immer häufiger das Drahtseil in
          seinen typischen Einsatzbereichen.
        </ProductCard>

        <ProductCard
          siteId="wassersport"
          title="Wassersportprogramm"
          revealDelay={300}
        >
          Schoten, Fallen und Festmacher für den Wassersport
        </ProductCard>

        <ProductCard
          siteId="dynagold"
          title="Dynagold - Angelschnüre für den Fachhandel"
          revealDelay={450}
        >
          Dynagold ist das premium Angelschnurprogramm, das exklusiv über
          führende Fachhändler vertrieben wird.
        </ProductCard>

        <ProductCard
          siteId="hoistline"
          title="Hoistline - Segelflug Windenstartseile"
          revealDelay={600}
        >
          Hochleistungsstartseile mit der Erfahrung aus über 15 Jahren
          Entwicklung.
        </ProductCard>
      </div>
    </div>
  );
}

export default Produkte;
