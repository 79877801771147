import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import ContentSection from './ContentSection';
import PageBanner from './PageBanner';

const Container = styled.div`
  grid-auto-flow: dense;

  @media only screen and (max-width: 720px) {
    grid-template-columns: 1fr !important;
  }
`;

function Custom() {
  return (
    <>
      <ContentSection>
        <div className="wrapper">
          <Container className="grid two">
            <Fade duration={1000}>
              <StaticImage
                className="media"
                src="../../images/custom1.jpg"
                alt="Seile"
                style={{ backgroundPosition: 'top' }}
              />
            </Fade>
            <div>
              <Fade bottom duration={1000}>
                <h3>Passend für Sie gemacht</h3>
              </Fade>
              <Fade bottom duration={1000} delay={500}>
                <div>
                  <h4 className="serif">
                    Taklerei | Konfektionierung | hochwertig
                  </h4>
                  <p>
                    Mit der Herstellung des eigentlichen Seiles ist es oft nicht
                    getan. So führen wir in speziellen Abteilungen nach alter
                    handwerklicher Tradition und neuesten Techniken alle Arten
                    von Konfektionierungen durch. Vom Netz für den
                    Kindesspielplatz bis zum Sicherungsseil im Maschinenbau aus
                    unseren Dynatec High-Tech Seilen. So entstehen aus unseren
                    hochwertigen Seilen fertig anwendbare Produkte für den
                    Markt.
                  </p>
                </div>
              </Fade>
            </div>
          </Container>
        </div>
      </ContentSection>
      <PageBanner image="/mützen_01.jpg" />
    </>
  );
}

export default Custom;
