import React from 'react';
import styled from 'styled-components';
import { BsMouse } from 'react-icons/bs';
import { useScrollPos } from '../hooks';
import { Fade } from 'react-reveal';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const PageScrollIndicator = () => {
  const { scrollY } = useScrollPos();

  const opacity = scrollY < 125 ? 1 : 0;

  return (
    <Fade>
      <Container className="space center" style={{ opacity }}>
        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ ease: 'easeOut', repeat: Infinity, duration: 2 }}
        >
          <AnchorLink to="/#betrieb" aria-label="Scroll page">
            <BsMouse color="var(--primary-color)" size={36} />
          </AnchorLink>
        </motion.div>

        {/* <span>scroll</span> */}
      </Container>
    </Fade>
  );
};

const Container = styled.div`
  color: var(--primary-color);
  font-size: 0.85em;
  transition: opacity 200ms ease-out;
`;

export default PageScrollIndicator;
