import React from 'react';

import ContactForm from '../../components/ContactForm';
import ContentSection from './ContentSection';
import NewsContainer from './NewsContainer';

function Kontakt({ data }) {
  return (
    <ContentSection id="kontakt">
      <div className="wrapper m" style={{ maxWidth: '720px' }}>
        <ContactForm />

        <NewsContainer data={data} field="NT2" />
      </div>
    </ContentSection>
  );
}

export default Kontakt;
