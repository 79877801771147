const data = [
  {
    title: 'Wie alles begann',
    subtitle: '1850 | Reeperbahn | Altenwerder | Mädchen',
    body: `Im Jahre 1850 blieb der Seilermeister Friedrich Lippmann, Sohn einer Leineweberfamilie aus Klein Heerde
    im
    Harz, auf der Wanderschaft auf Altenwerder, einer Elbinsel bei Hamburg, “hängen”, weil er hier ein Mädchen
    gefunden hatte, mit dem er in Zukunft zusammenleben wollte. Außerdem erschienen ihm die beruflichen
    Perspektiven hier besonders interessant, nämlich viel Landwirtschaft, Fischerei sowie der große Hamburger
    Hafen
    vor der Tür...`,
    media: [
      {
        type: 'image',
        src: '/chronik/beginn_01.jpg',
        alt: 'beginn_01',
      },
      {
        type: 'image',
        src: '/chronik/beginn_04.jpg',
        alt: 'beginn_01',
      },
      {
        type: 'image',
        src: '/chronik/beginn_03.jpg',
        alt: 'beginn_01',
      },
      {
        type: 'image',
        src: '/chronik/beginn_02.jpg',
        alt: 'beginn_01',
      },
    ],
    bigImg: {
      src: '/chronik/tradition_02.jpg',
      alt: 'bild005_02',
    },
  },
  {
    title: 'Tradition...',
    subtitle: 'Generationen | Seilerei | Neuer Betrieb',
    body: `Unter diesen Aspekten sah Friedrich Lippmann günstige Voraussetzungen zur Gründung eines Seilereibetriebes. Am 13. August nahm er seinen Betrieb auf. Inzwischen haben 5 Generationen diese Tradition fortgesetzt und fast jede baute einen neuen Betrieb, weil jeweils triftige Argumente dafür sprachen. Drei Generationen produzierten in Hamburg-Altenwerder...`,
    media: [
      {
        type: 'image',
        src: '/chronik/tradition_01.jpg',
        alt: 'tradition_01',
      },
      {
        type: 'image',
        src: '/chronik/tradition_02.jpg',
        alt: 'tradition_02',
      },
      {
        type: 'image',
        src: '/chronik/tradition_03.jpg',
        alt: 'tradition_03',
      },
      {
        type: 'image',
        src: '/chronik/tradition_04.jpg',
        alt: 'tradition_04',
      },
    ],
    bigImg: {
      src: '/chronik/tradition_03.jpg',
      alt: 'bild005_02',
    },
  },
  {
    title: 'Eine neue Generation...',
    subtitle: 'Neue Ideen | Erfahrung | Einsatz',
    body: `1971 trat Textilingenieur  Klaus Lippmann in den Betrieb ein. Er führte das traditionelle handwerkliche Unternehmen mit neuen Ideen und viel persönlichen Einsatz in die heutige Zeit. 1982 verließ er mit dem Betrieb Altenwerder und baute die heutige Fabrik mit über 14.000 qm Betriebsfläche...`,
    media: [
      {
        type: 'image',
        src: '/chronik/generation_01.jpg',
        alt: 'generation_01',
      },
      {
        type: 'image',
        src: '/chronik/generation_02.jpg',
        alt: 'generation_02',
      },
      {
        type: 'image',
        src: '/chronik/generation_03.jpg',
        alt: 'generation_03',
      },
    ],
    bigImg: {
      src: '/chronik/generation_02.jpg',
      alt: 'generation_02',
    },
  },
  {
    title: 'Der neue Betrieb...',
    subtitle: '342 Meter | neueste Methoden | Hamburg-Hausbruch',
    body: `Drei Generationen produzierten in Hamburg-Altenwerder, die vierte Generation mit Klaus Lippmann baute den jetzigen Betrieb am Dubbenwinkel in Hamburg-Hausbruch.  So ist es möglich, dass trotz alter Tradition und weitervererbten Fachwissens stets nach den neuesten Methoden produziert wird.  Nach wie vor werden ein Teil der Seile auf der 342 Meter langen Reeperbahn ausgetrieben und geschlagen. 2009 wurde aus Lippmann Tauwerk die
    Lippmann German Ropes...`,
    media: [
      {
        type: 'image',
        src: '/chronik/betrieb_01.jpg',
        alt: 'generation_01',
      },
      {
        type: 'image',
        src: '/chronik/betrieb_02.jpg',
        alt: 'generation_02',
      },
      {
        type: 'image',
        src: '/chronik/betrieb_03.jpg',
        alt: 'generation_03',
      },
      {
        type: 'image',
        src: '/chronik/betrieb_04.jpg',
        alt: 'generation_03',
      },
      {
        type: 'image',
        src: '/chronik/betrieb_05.jpg',
        alt: 'generation_03',
      },
    ],
    bigImg: {
      src: '/chronik/betrieb_03.jpg',
      alt: 'generation_02',
    },
  },
  {
    title: 'Moderne Produktion...',
    subtitle: '150 Flechtmaschinen | 0,1 bis 200 mm | führend',
    body: `Heute besteht der Maschinenpark neben zahlreichen Seilschlagmaschinen und der Reeperbahn auch aus über 150 Flechmaschinen mit denen Seile von 0,1 bis 160 mm geflochten werden können. Modernste Imprägnierungs- und Veredelungsanlagen runden den Maschinenstamm ab. Damit ist Lipmann German Ropes einer der führenden Seilhersteller der Branche, mit mehr als 10.000 Kunden im In- und Ausland...`,
    media: [
      {
        type: 'image',
        src: '/chronik/modern_01.jpg',
        alt: 'generation_01',
      },
      {
        type: 'image',
        src: '/chronik/modern_02.jpg',
        alt: 'generation_02',
      },
      {
        type: 'video',
        src: '/chronik/modern_03.MOV',
        alt: 'generation_03',
      },
      {
        type: 'video',
        src: '/chronik/modern_04.MOV',
        alt: 'generation_03',
      },
    ],
    bigImg: {
      src: '/chronik/modern_02.jpg',
      alt: 'generation_02',
    },
  },
];

export default data;
