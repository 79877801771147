import styled from 'styled-components';

export default styled.section`
  background-color: var(--backgroundColor);
  padding-top: 10em;
  padding-bottom: 10em;

  h3 {
    font-size: 2.45em;
    margin: 0;
    margin-bottom: 0.5em;
  }

  h4 {
    font-size: 1.2em;
    font-weight: 400;
  }

  p {
    line-height: 165%;
    font-size: 1.1em;
  }
`;
