import '../styles/global.scss';

import Axios from 'axios';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import {
  About,
  AtoZ,
  Chronik,
  Custom,
  Flex,
  Hero,
  Kontakt,
  Produkte,
  Programm,
} from '../modules/home';
import PageBanner from '../modules/home/PageBanner';
import PageScrollIndicator from '../components/PageScrollIndicator';

// TODO: Add body, overflow: hidden to prevent horizontal overflow with paralax
const IndexPage = ({ location }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios(
          `${process.env.GATSBY_API_URL}/microsite/lippmann/idb`
        );
        setData(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Seo title="Home" path={location.pathname} />
      <Layout>
        <ParallaxProvider>
          <Hero />
          <PageScrollIndicator />
          <About data={data} />
          <Chronik />
          <Programm />
          <Custom />
          <Flex />
          <AtoZ />

          <Produkte />

          <PageBanner image="/luftbild_kl.jpg" />

          <Kontakt data={data} />
        </ParallaxProvider>
      </Layout>
    </>
  );
};

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`;

export default IndexPage;
