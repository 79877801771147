import React from 'react';

const Loader = ({ loading = true }) => {
  if (!loading) return null;

  return (
    <div className="loader">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loader;
