import React from 'react';
import { Fade } from 'react-reveal';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const StyledHero = styled.div`
  height: calc(100vh - 250px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--borderColor);

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(55%);
  }

  .welcome {
    color: #fff;
    z-index: 1;
    text-align: center;
    padding-top: 25px;

    h1 {
      font-size: 4em;
      margin-bottom: 0.65em;
    }

    h2 {
      font-weight: 400;
    }
  }
`;

function Hero() {
  return (
    <StyledHero>
      <video poster="/bahnsmall.jpg" muted autoPlay loop playsInline>
        <source
          src="https://contentcrawler.de/media/LGR_Image_01_2.mp4"
          type="video/mp4"
        />
      </video>

      <Parallax speed={40} className="wrapper m welcome">
        <h1>Lippmann German Ropes</h1>
        <h2>Seit über 150 Jahren stellen wir Seile her</h2>
        <Fade bottom delay={500}>
          <AnchorLink to="/#betrieb" className="button primary">
            Mehr erfahren
          </AnchorLink>
        </Fade>
      </Parallax>
    </StyledHero>
  );
}

export default Hero;
