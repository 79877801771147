import React from 'react';

import chronikData from '../../data/chronikData';
import ChronikSection from './ChronikSection';

function Chronik() {
  return (
    <section id="chronik">
      {chronikData.map((entry, index) => (
        <ChronikSection key={index} {...entry} />
      ))}
    </section>
  );
}

export default Chronik;
