import React from 'react';

import Loader from '../../components/Loader';

function NewsContainer({ data, field }) {
  if (!data) return <Loader />;

  return <div dangerouslySetInnerHTML={{ __html: data[field] }}></div>;
}

export default NewsContainer;
