import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import ContentSection from './ContentSection';
import PageBanner from './PageBanner';

const ImageGrid = styled.div`
  @media only screen and (max-width: 720px) {
    gap: 1em;
  }
`;

function Custom() {
  return (
    <>
      <ContentSection>
        <Fade>
          <div className="wrapper">
            <div>
              <Fade bottom>
                <h3>
                  <span style={{ color: 'var(--primary-color)' }}>
                    Flexibilität.
                  </span>{' '}
                  Alltag für uns
                </h3>
              </Fade>
              <Fade bottom>
                <p>
                  Nur ein perfektes Produkt herzustellen ist heute oft nicht
                  genug. Erst die hohe Flexibilität in der Produktion, möglich
                  durch IT gestützte Fertigungs- und Planungssysteme, gepaart
                  mit einer modernen Lager- und Materiallogistik machen oft den
                  Unterschied zum Wettbewerb aus.
                </p>
              </Fade>
              <Fade bottom delay={500}>
                <ImageGrid className="grid three space">
                  <StaticImage
                    src="../../images/flex1.jpg"
                    className="media"
                    alt=""
                  />
                  <StaticImage
                    src="../../images/flex2.jpg"
                    className="media"
                    alt=""
                  />
                  <StaticImage
                    src="../../images/flex3.jpg"
                    className="media"
                    alt=""
                  />
                </ImageGrid>
              </Fade>
            </div>
          </div>
        </Fade>
      </ContentSection>
      <PageBanner image="/dsc_3781_01.jpg" />
    </>
  );
}

export default Custom;
