import React from 'react';

import NewsContainer from './NewsContainer';

function About({ data }) {
  return (
    <section id="betrieb">
      {data?.NT1 && (
        <div style={{ paddingTop: '32px' }}>
          <div className="wrapper m space">
            <NewsContainer data={data} field="NT1" />
          </div>
        </div>
      )}
    </section>
  );
}

export default About;
