import React from 'react';
import { Fade } from 'react-reveal';
import styled from 'styled-components';

import ContentSection from './ContentSection';
import PageBanner from './PageBanner';

const Content = styled.div`
  grid-auto-flow: dense;
  align-items: center;
  grid-template-columns: 3fr 4fr;
  column-gap: 4em;

  @media only screen and (max-width: 1020px) {
    grid-template-columns: 1fr !important;
  }
`;

const FADE_DURATION = 1000;

const ChronikSection = ({ title, subtitle, body, media, bigImg }) => {
  return (
    <>
      <ContentSection>
        <div className="wrapper l">
          <Content className="grid">
            <Fade duration={FADE_DURATION}>
              {media[0].type === 'image' && (
                <img
                  className="media"
                  style={{ zIndex: 50 }}
                  src={media[0].src}
                  alt={media[0].alt}
                />
              )}
            </Fade>
            <div>
              <Fade bottom duration={FADE_DURATION}>
                <h3>{title}</h3>
              </Fade>
              <Fade bottom duration={FADE_DURATION} delay={200}>
                <div>
                  <h4>{subtitle}</h4>
                  <p>{body}</p>
                </div>
              </Fade>
            </div>
          </Content>
        </div>
      </ContentSection>
      <PageBanner image={bigImg.src} />
    </>
  );
};

export default ChronikSection;
